// extracted by mini-css-extract-plugin
export var album = "album-module--album--dafc2";
export var direction = "album-module--direction--06c78";
export var duration = "album-module--duration--63d1e";
export var grid = "album-module--grid--fdffa";
export var links = "album-module--links--72452";
export var meta = "album-module--meta--a6275";
export var nav = "album-module--nav--f46ed";
export var number = "album-module--number--dbce4";
export var spotify = "album-module--spotify--e228e";
export var tags = "album-module--tags--fc913";
export var tracks = "album-module--tracks--3d721";