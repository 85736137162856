import { getDecade, formatDuration, intervalToDuration } from "date-fns";
import _ from "lodash";


export const augmentAlbums = (albums) => {
  return albums.map(alb => ({
    ...alb,
    ...(alb.year !== null && {
      age: new Date(alb.played_on) - new Date(alb.year, 0, 1),
    })
  }));
};

export const years = (albums) => {
  const years = _
    .countBy(albums, "year")
  const decades = _
    .countBy(albums.map(a => (
      getDecade(new Date(a.year, 0, 1))
    )));
  return {
    all: years,
    decades,
    oldest: Object.keys(years)[0],
    newest: Object.keys(years).filter(Number).slice(-1)[0],
  };
};

export const processTracks = (albums) => {
  albums = _.filter(albums, a=>a.tracks).map(alb => ({
    ...alb,
    length: alb.tracks.reduce((total, track) => track.duration + total,0),
    count: alb.tracks.length,
  }));
  return {
    byLength: _.sortBy(albums, "length"),
    totalLength: albums.reduce((total, album) => total + album.length,0),
    byCount: _.sortBy(albums, "tracks"),
    totalCount: albums.reduce((total, album) => total + album.count,0),
  };
};

export const byArtist = (albums) => {
  return albums.reduce((artists, album) => ({
    ...artists,
    [album.artist]: [].concat(
      album,
      artists[album.artist]
        ? artists[album.artist]
        : [],
    )
  }), {});
};

export const hostsByCount = (albums) => {
  const hosts = _.countBy(albums, "host");
  return hosts;
};

export const hostsByPlayed = (albums) => {
  const hosts = _.sortBy(albums, "played_on").reverse();
  return _.uniqBy(hosts, "host");
};

// From and To are numbers (e.g. 1998).
export const buildDates = (from, to) => {
  from = parseInt(from, 10);
  to = parseInt(to, 10);
  return Array.from(Array(to-from+1), (e, i) => i+from);
};

// start and end are dates.
export const toDuration = (start, end, mode) => (
  formatDuration(intervalToDuration({
    start,
    end,
  }), {
    delimiter: ", ",
    format: mode === "days"
      ? ["years", "months", "weeks", "days"]
      : ["years", "months", "weeks", "days", "hours", "minutes", "seconds"],
  })
);

export const secondsToDuration = (seconds) => {
  const start = new Date(1970, 0, 1);
  const end = new Date(start);
  end.setSeconds(seconds);
  return toDuration(start, end);
};

export const toMinutes = function(t) {
  var m, min, r;
  m = 60;
  r = t % m;
  min = (t - r) / m;
  return min + ':' + pad(r);
};

export const pad = function(n) {
  return ('00' + n).slice(-2);
};
